import React from 'react'
import Home from './home'
import { language } from '../buildConfig.js'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import SocialMedia from '../components/SocialMedia'
import PropTypes from 'prop-types'
import { layout, seo } from '../messages.js'

import Layout from '../layout'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	isHomepage = () => {
		const location = this.props.location
		return (
			location.pathname === '/' ||
			location.pathname.indexOf('/#') === 0 ||
			location.pathname === '/index.html' ||
			location.pathname === '/fr'
		)
	}

	componentDidMount = () => {
		this.setState({
			currentHashRoute: window.location.pathname + window.location.hash,
		})
	}

	updateHashRoute = (route) => {
		const currRoute = route === '/' ? '' : `#${route}`

		this.setState({
			currentHashRoute: currRoute,
		})
	}

	render = () => {
		return (
			<Layout language={language} page='home'>
				<Home
					lang={language}
					{...this.props}
					updateParent={this.updateHashRoute}
				/>
			</Layout>
		)
	}
}

export default IndexPage
